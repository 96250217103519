var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('img', {
    staticClass: "exit-button",
    attrs: {
      "src": require("@/assets/icons/x-red.svg")
    },
    on: {
      "click": function click($event) {
        return _vm.exit();
      }
    }
  }), _c('div', {
    staticClass: "text-space"
  }, [_c('div', {
    staticClass: "text-space__content",
    domProps: {
      "innerHTML": _vm._s(_vm.innerHTML)
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
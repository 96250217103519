









import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";
@Component({
  components: {}
})
export default class TextContainer extends Vue {
  @Prop() innerHTML!: string;
  @Prop() from!: string;

  mounted(){

  }

  exit(): void {
    this.$router.push(this.from ? {name: this.from} : '/');
  }
}
